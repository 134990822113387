<template>
  <div>
    <v-card class="rounded my-2 fill-height" style="position: relative;">
      <v-card-title class="white--text my-1 py-1 pt-4">
        <h4 v-if="showProrateField" style="color:#30475A;">Upgrade Plan</h4>
        <h4 v-else style="color:#30475A;">
          Purchase Plan
        </h4>
      </v-card-title>
      <v-card-text class="ma-1 pa-2">
        <v-row class="ma-0 pa-0">
          <v-badge
            v-if="checkProrate"
            bordered
            color="info"
            icon="mdi-lock"
            overlap
          >
            <v-btn class="white--text" color="error" depressed>
              Prices Prorated Till :
              {{ proratedDate }}
            </v-btn>
          </v-badge>
        </v-row>
        <v-row class="ma-0 pt-3" style="padding-bottom: 20px;">
          <v-badge
            v-if="checkDiscount"
            bordered
            color="success"
            icon="mdi-credit-card-off"
            overlap
          >
            <v-btn class="white--text" color="error" depressed>
              {{ planObj.offerDiscount }} % off
            </v-btn>
          </v-badge>
        </v-row>

        <div class="duration-container">
          <span v-for="(cycle, index) in planCycle" :key="index">
            <v-btn
              class="tab-button"
              :class="{ 'active-tab': selectedPlanCycle === cycle.value }"
              @click="setCycle(cycle.value)"
            >
              {{ cycle.name }}
            </v-btn>
          </span>
        </div>

        <div class="price-container" style="padding-top: 20px;">
          <div class="price-details">
            <div v-if="planObj.realPricePercentage > 0" class="original-price">
              Rs {{ planObj.realPrice }}
            </div>
            <div class="discounted-price" style="padding-left: 6px;">
              <span>Rs</span>
              {{ planObj.perUserFinalCost || 0 }}
              <span style="font-size: 16px;color:grey;font-weight: 400;"
                >/user</span
              >
              <span
                v-if="planObj.realPricePercentage > 0"
                class="discount-percentage"
              >
                ({{ planObj.realPricePercentage }}% Off)
              </span>
            </div>
          </div>
          <div class="final-price-info" style="padding-top: 20px;">
            You will be charged Rs {{ planObj.price }} (+Taxes) per
            {{ selectedPlanCycle.slice(0, -2) }}
          </div>
        </div>

        <v-row class="ma-0 px-0 py-3 pt-8" justify="center" align="center">
          <v-row>
            <v-col
              cols="12"
              sm="5"
              align="right"
              style="padding-top: 24px;"
              class="hidden-xs-only"
            >
              Total Member Count
            </v-col>
            <v-col cols="3" sm="1" align="right" style="padding-top: 20px;">
              <v-btn
                class="inverted--text"
                color="#37cf37"
                fab
                x-small
                @click="setUserCount('minus')"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" sm="2">
              <v-text-field
                v-model="userCount"
                label="User Count"
                solo
                background-color="inverted"
                type="number"
                @keyup="setUserCount"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="1" style="padding-top: 20px;">
              <v-btn
                class="inverted--text"
                color="#37cf37"
                fab
                x-small
                @click="setUserCount('plus')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="3" sm="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    style="opacity: 0.7"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    info_outline
                  </v-icon>
                </template>
                <span
                  >For example, if you have 3 members and you want to add one
                  more, make Total Member Count 4. <br />
                  For example, if you have 3 members and you want to reduce,
                  delete the 3rd member and make Total Member Count 2.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-container fill-height style="display:none;">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="3">
                <v-switch v-model="prorate" label="Prorate Prices"></v-switch>
              </v-col>
              <v-col cols="12" sm="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      style="opacity: 0.7"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      info_outline
                    </v-icon>
                  </template>
                  <span>
                    GoDial will automatically adjust pricing based on the expiry
                    date in case you change the number of users. If you wish to
                    generate an invoice before your expiry date, you can turn
                    off prorate.
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-row>

        <!-- Cloud Call Recording Toggle -->
        <!-- <v-row class="ma-0 px-0 py-3 pt-0" justify="center" align="center">
          <v-col
            cols="12"
            sm="5"
            class="d-flex justify-end align-center"
            align="center"
          >
            <span style="font-size: 16px; color: #30475A; margin-right: 12px;">
              Cloud Call Recording
            </span>
            <v-switch
              v-model="cloudCallRecording"
              inset
              @change="getBillingCost"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="10" align="center">
            <p class="grey--text">
              Record outgoing calls using GoDial's Cloud Connect System
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  GoDial places one call to our recording line and one call to
                  the number you want to connect to. Once the calls are
                  connected, you will be required to press the merge button.
                  This works on all android devices. Make sure you input the
                  correct phone number of the agent and set the default dialer
                  to Phone Dialer with Call Rec.
                </span>
              </v-tooltip>
            </p>
          </v-col>
        </v-row> -->

        <v-row justify="center" align="center">
          <v-col cols="12" sm="9" align="center">
            <v-radio-group
              v-model="cloudCallRecording"
              row
              @change="getBillingCost"
            >
              <v-radio
                label="Without Cloud Recording"
                v-bind:value="false"
              ></v-radio>
              <v-radio
                label="With Cloud Recording"
                v-bind:value="true"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="10" align="center" v-if="cloudCallRecording">
            <p class="grey--text">
              Record outgoing calls using GoDial's Cloud Connect System
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span style="max-width: 500px;">
                  GoDial places one call to our recording line and one call to
                  the number you want to connect to. <br />Once the calls are
                  connected, you will be required to press the merge button.<br />
                  This works on all android devices. Make sure you input the
                  correct phone number of the agent and set the default dialer
                  to Phone Dialer with Call Rec.
                </span>
              </v-tooltip>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0 pa-0" justify="center">
          <v-btn class="primarygrad" dark @click="payment">PAY</v-btn>
        </v-row>
      </v-card-actions>

      <div style="padding-bottom: 30px;"></div>
    </v-card>

    <v-card v-if="billings.length > 0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="billings" :search="search">
        <template v-slot:item.invoice="{ item }">
          <!-- :color="orange" -->
          <v-chip v-if="item.paid && !item.deleted" dark color="success">
            Paid
          </v-chip>

          <v-chip
            v-else-if="!item.paid && !item.deleted"
            class="primarygrad"
            @click="pay(item)"
          >
            Pay
          </v-chip>

          <v-chip v-else dark color="error">
            Deleted
          </v-chip>

          <!-- <v-chip v-else> </v-chip> -->
        </template>

        <template v-slot:item.createdOn="{ item }">
          {{ $moment(item.createdOn).format("lll") }}
        </template>

        <template v-slot:item.expiryDate="{ item }">
          <div v-if="item.expiryDate">
            {{ $moment(item.expiryDate).format("lll") }}
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          {{ item.amount }} {{ item.currency }}
        </template>

        <template v-slot:item.paid="{ item }">
          <v-chip
            v-if="!item.deleted && item.paid"
            dark
            @click="invoiceLinkOpen(item.invoice)"
          >
            Invoice
          </v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="!item.paid && !item.deleted"
            icon
            color="error"
            @click="removeInvoice(item.id)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            We are trying to verify the purchase please wait
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
        We are trying to verify the purchase please wait
        <v-btn @click="dismissInterval">Dismiss</v-btn>
      </h3>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const _ = require("lodash");
import fb from "../../js/firebase";
export default {
  data() {
    return {
      planCycle: [
        { name: "Monthly", value: "monthly" },
        { name: "Halfyearly", value: "halfyearly" },
        { name: "Yearly", value: "yearly" },
      ],
      prorate: true,
      selectedPlanCycle: "monthly",
      userCount: 1,
      originalUserCount: 1,
      proreteUserCount: 1,
      search: "",
      cloudCallRecording: false,
      overlay: false,
      headers: [
        // { text: "Paid", value: "paid" },
        { text: "Billing Date", value: "createdOn" },
        { text: "User", value: "user" },
        { text: "Amount", value: "amount" },
        { text: "Expiry Date", value: "expiryDate" },
        { text: "Status", value: "invoice" },
        { text: "Invoice", value: "paid" },
        { text: "Action", value: "action" },
      ],
      billings: [],
      planObj: {},
      loader: false,
      planStatus: false,
      showProrateField: false,
      remainingDay: 0,
      // discount: 10,
      promoCode: "",
    };
  },
  computed: {
    discount() {
      if (!this.prices) return 0;
      if (this.planCycle == "Monthly") {
        return 0;
      } else {
        var monthly = _.find(this.prices, (p) => {
          return p.subscription.interval == "month";
        });

        var yearly = _.find(this.prices, (p) => {
          return p.subscription.interval == "year";
        });

        var monthlyPrice = Number(monthly.price.net).toFixed(2);
        var yearlyPrice = Number(yearly.price.net).toFixed(2);

        var discount =
          ((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100;

        return discount.toFixed(0);
      }
    },
    paperplane() {
      return require("../../assets/paperplane.png");
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    proratedDate() {
      return this.$moment(this.companyDetails.plan.expiryDate)
        .subtract(2, "days")
        .format("lll");
    },
    user() {
      return this.$store.getters.user;
    },
    checkDiscount() {
      if (
        this.planObj &&
        typeof this.planObj.offerDiscount !== "undefined" &&
        this.planObj.offerDiscount !== "0"
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkProrate() {
      if (this.companyDetails.plan.name != "Trial" && this.remainingDay > 1) {
        return true;
      } else {
        return false;
      }
    },
    hasPromoCode() {
      return typeof this.$store.getters.companyDetails.paymentProfile
        .promoCode != "undefined" &&
        this.$store.getters.companyDetails.paymentProfile.promoCode != ""
        ? true
        : false;
    },
  },
  created() {
    this.init();
  },
  methods: {
    removePromo() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/promos/promo-remove-company`)
        .then((respo) => {
          this.refreshCompany();
          this.getBillingCost();
          return this.$swal({
            type: "success",
            text: "Promo Code Successfully Applied.",
          });
        })
        .catch((err) => {
          console.log("Error On Apply Promo", err);
          return this.$swal({
            type: "error",
            text: err.body.error.message,
          });
        });
    },
    applyPromo() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/promos/promo-check-apply`, {
          promoCode: this.promoCode,
          totalUser: this.userCount,
        })
        .then((respo) => {
          this.refreshCompany();
          this.getBillingCost();
          return this.$swal({
            type: "success",
            text: "Promo Code Successfully Applied.",
          });
        })
        .catch((err) => {
          console.log("Error On Apply Promo", err);
          return this.$swal({
            type: "error",
            text: err.body.error.message,
          });
        });
    },
    refreshCompany() {
      this.$store.dispatch("getCompanyDetails", this.user);
    },
    removeInvoice(itemId) {
      // const self = this;
      this.$swal({
        text: "Click Confirm to Remove Invoice",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "info",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.value) {
          this.loader = true;
          this.$http
            .post(`${this.$store.state.ENDPOINT}/billings/remove-invoice`, {
              billingId: itemId,
            })
            .then((respo) => {
              this.loader = false;
              this.getBillings();
              return this.$swal({
                type: "success",
                text: "Invoice Deleted Successfully",
              });
            })
            .catch((err) => {
              this.loader = false;
              console.log("Error: in delete Invoice", err);
              return this.$swal({
                type: "error",
                text: "Something went wrong.",
              });
            });
        }
      });
    },
    async init() {
      await this.refreshCompany();

      if (this.companyDetails) {
        // PromoCode Check
        this.promoCode =
          typeof this.companyDetails.paymentProfile.promoCode != "undefined"
            ? this.companyDetails.paymentProfile.promoCode
            : "";

        this.remainingDay = this.$moment(
          this.companyDetails.plan.expiryDate
        ).diff(this.$moment().format(), "days");

        if (this.companyDetails.plan.name != "Trial" && this.remainingDay > 1) {
          this.planCycle = _.filter(this.planCycle, (p) => {
            //Santize Monthy
            if (
              ["annual", "yearly", "year", "annualy"].includes(
                this.companyDetails.plan.cycle.toLowerCase()
              )
            ) {
              this.companyDetails.plan.cycle = "yearly";
              // this.selectedPlanCycle = "yearly";
            }
            if (
              ["monthy", "month"].includes(
                this.companyDetails.plan.cycle.toLowerCase()
              )
            ) {
              this.companyDetails.plan.cycle = "monthly";
            }

            return p.value == this.companyDetails.plan.cycle;
          });

          this.selectedPlanCycle = this.planCycle[0].value;

          if (this.companyDetails.plan.name.includes("With Rec")) {
            this.cloudCallRecording = true;
          }

          // this.cloudCallRecording = this.companyDetails.plan.cloudCallRecording;
        }
        this.userCount = this.companyDetails.plan.userLimit;
      }

      // console.log("this.userCount", this.userCount);

      await this.fetchUserCount();
      await this.getBillings();

      if (this.companyDetails.plan.name == "Trial") {
        this.userCount = this.originalUserCount;
      }
      await this.getBillingCost();
    },
    async payment() {
      if (!this.companyDetails.paymentProfile.activated) {
        return this.$swal({
          type: "warning",
          text:
            "Please check and update your payment profile, before generating invoice",
        });
      }

      if (
        this.billings.length > 0 &&
        !this.billings[0].paid &&
        typeof this.billings[0].deleted == "undefined"
      ) {
        return this.$swal({
          type: "warning",
          text:
            "Your last invoice is unpaid. Kindly pay or delete the invoice to generate a new one.",
        });
      }

      await this.fetchUserCount();

      if (this.userCount < this.originalUserCount) {
        return this.$swal({
          type: "warning",
          text:
            "Your account has " +
            this.originalUserCount +
            " members, please increase the no of members",
        });
      }

      await this.zohoPayment();
    },
    zohoPayment() {
      var invoiceObj = {
        itemName: this.selectedPlanCycle,
        prorate: this.prorate,
        withCloudRec: this.cloudCallRecording,
      };

      invoiceObj.quantity = Number(this.userCount);
      if (
        this.companyDetails.plan.name != "Trial" &&
        this.remainingDay > 1 &&
        this.prorate
      ) {
        invoiceObj.quantity =
          Number(this.userCount) - Number(this.companyDetails.plan.userLimit);
      }

      if (this.prorate) {
        if (!(Number(invoiceObj.quantity) > 0)) {
          return this.$swal({
            type: "warning",
            text: "Please Increase Member for Payment",
          });
        }
      }

      this.loader = true;
      this.$http
        .post(
          `${this.$store.state.ENDPOINT}/billings/create-payment-invoice`,
          invoiceObj
        )
        .then((respo) => {
          this.loader = false;
          this.getBillings();
          this.$swal({
            text: "Your bill is generated, Proceed to payment",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "info",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.value) {
              this.invoiceLinkOpen(respo.body.invoiceLink);

              this.watchForPayment(respo.body.billing.id);
            }
          });
        })
        .catch((err) => {
          this.loader = false;
          console.log("Error: Zoho Create Invoice Error", err);

          return this.$swal({
            type: "error",
            text: err.body.error.message,
          });
        });
    },
    async fetchUserCount() {
      var where = {
        companyId: {
          eq: this.$store.getters.user.companyId,
        },
        role: {
          neq: "ApiManager",
        },
        username: {
          neq: "adminhelper",
        },
      };

      await this.$http
        .get(
          `${
            this.$store.state.ENDPOINT
          }/accounts/count?where=${encodeURIComponent(JSON.stringify(where))}`
        )
        .then((response) => {
          response = response.body;
          // this.userCount = response.count;
          this.originalUserCount = response.count;

          this.$emit("update-original-user-count", this.originalUserCount);
        })
        .catch((err) => {
          // this.userCount = 10; // set to default
          console.log("Err: Error in count", err);
        });
    },
    pay(billing) {
      //  Number(this.originalUserCount) >
      //     Number(billing.user) + Number(this.companyDetails.plan.userLimit) ||
      //   (this.companyDetails.plan.name == "Trial" &&
      //     Number(this.originalUserCount) > Number(billing.user))

      if (Number(this.originalUserCount) > Number(billing.userLimit)) {
        return this.$swal({
          type: "error",
          text:
            "There are more users in your account than this bill's user count. Kindly reduce the no of members or delete this bill and recreate a new one.",
        });
      }

      this.invoiceLinkOpen(billing.invoice);

      this.watchForPayment(billing.id);
    },
    invoiceLinkOpen(invoiceLink) {
      window.open(invoiceLink, "_blank").focus();
    },
    setCycle(cycle) {
      this.selectedPlanCycle = cycle;
      this.getBillingCost();
    },
    dismissInterval() {
      clearInterval(this.intervalCheck);
      this.overlay = false;
    },

    async watchForPayment(billId) {
      this.overlay = true;
      const self = this;
      var checkForPayment = function(billId, self) {
        self.$http
          .get(self.$store.state.ENDPOINT + "/billings/" + billId)
          .then(async function(bill) {
            bill = bill.data;

            if (bill.paid) {
              self.overlay = false;
              clearInterval(self.intervalCheck);

              var item = bill.serverData[0].line_items[0].name;
              var amount = bill.amount;
              var currency = "INR";
              var order_number = bill.serverData[0].invoice_id;
              var subscription_id =
                bill.serverData[0].line_items[0].item_id + bill.companyId;

              fb.log("purchase", {
                item: item,
                price: {
                  amount: amount,
                  currency: currency,
                },
                currency: "INR",
                value: amount,
                order_number: order_number,
                subscription_id: subscription_id,
              });

              self.reloadPage();
            }
          });
      };
      this.intervalCheck = setInterval(checkForPayment, 2000, billId, self);
    },

    reloadPage() {
      this.refreshCompany();

      setTimeout(function() {
        window.location.reload();
      }, 5000);
    },

    setUserCount(type) {
      var self = this;

      if (type == "plus" || type == "minus") {
        this.userCount = type == "plus" ? ++this.userCount : --this.userCount;
      }

      console.log(this.companyDetails.plan.userLimit);

      this.remainingDay = this.$moment(
        this.companyDetails.plan.expiryDate
      ).diff(this.$moment().format(), "days");

      if (this.companyDetails.plan.name != "Trial" && this.remainingDay > 1) {
        if (
          Number(this.userCount) < Number(this.companyDetails.plan.userLimit)
        ) {
          this.userCount = this.companyDetails.plan.userLimit;

          return this.$swal({
            title: "Error!",
            text: `You can not decrease member because you already have ${this.companyDetails.plan.userLimit} members in current plan.`,
            type: "error",
          });
        }
      }

      if (Number(this.userCount) < Number(this.originalUserCount)) {
        console.log("this.usercount", this.userCount);

        console.log("this.originalUserCount", this.originalUserCount);

        this.userCount = this.originalUserCount;

        return this.$swal({
          title: "Error!",
          text: `You can not decrease member because you already have ${this.originalUserCount} members.You need to delete member first to decrease.`,
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to Members",
        })
          .then((willGo) => {
            if (willGo.value) {
              self.$router.push({ path: "/users" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.debounceGetBillingCost();
    },
    debounceGetBillingCost: _.debounce(function() {
      this.getBillingCost();
    }, 1000),
    getBillingCost() {
      var calculationObj = {
        companyId: this.companyDetails.id,
        currentPlanName: this.companyDetails.plan.name,
        currentCycle: this.companyDetails.plan.cycle,
        selectedCycle: this.selectedPlanCycle,
        expiryDate: this.companyDetails.plan.expiryDate,
        withCloudRec: this.cloudCallRecording,
      };

      // Calculated Count Here
      calculationObj.user = Number(this.userCount);

      if (this.companyDetails.plan.name != "Trial" && this.remainingDay > 1) {
        calculationObj.user =
          Number(this.userCount) - Number(this.companyDetails.plan.userLimit);
        if (calculationObj.user < 0) {
          calculationObj.user = 0;
        }
      }

      this.loader = true;
      this.$http
        .post(
          `${this.$store.state.ENDPOINT}/billings/billing-calculation`,
          calculationObj
        )
        .then((respo) => {
          this.loader = false;
          this.planObj = respo.data;
          this.planObj.price = this.planObj.price.toFixed(2);
        })
        .catch((err) => {
          this.loader = false;
          console.log("Error: Getting Billing Calculation", err);
        });
    },
    async getBillings() {
      // var self = this;

      var filter = {
        where: {
          companyId: { eq: this.$store.getters.user.companyId },
          platform: "zoho",
        },
        order: `createdOn desc`,
      };

      await this.$http
        .get(
          `${this.$store.state.ENDPOINT}/billings?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        )
        .then((respo) => {
          this.billings = respo.data;
        })
        .catch((err) => {
          console.log("Err", err);
        });
    },
  },
};
</script>

<style scoped>
.v-toolbar__content {
  height: 0 !important;
}
.customBorder {
  border-style: solid;
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  width: 100%;
}
.centerimg {
  position: absolute;
  top: 30%;
  left: 30%;
}

/* Ribbon ************ */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: 69px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: 1px;
  top: 22px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .smallMobFont {
    font-size: 20px !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .smallMobFont {
    font-size: 20px !important;
  }
}

.primarygrad {
  background-image: linear-gradient(to right, #92df48, #1bca3f) !important;
  color: white;
}

.v-badge__badge {
  inset: auto auto calc(100% - 34px) calc(100% - 54px) !important;
}

span .v-badge__badge {
  border-radius: 10px;
  color: #fff;
  font-size: 25px;
  height: 32px;
  letter-spacing: 0;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3scubic-bezier (0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  inset: auto auto calc(100% - 34px) calc(100% - 56px);
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to right, #f14968, #ff0303);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.4rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  letter-spacing: 0.1rem;
  z-index: 999999;
}

.duration-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tab-button {
  background-color: white;
  color: rgb(155, 155, 155);
  border: 1px solid #37cf37;
  width: 150px;
  height: 90px;
  margin: 0 -2px 0 -2px !important;
  font-weight: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: background-color 0.3s, color 0.3s, border-color 0.3s; */
  border-radius: 0; /* Remove border radius for a flat edge */
  box-shadow: none;
}

.tab-button:first-child {
  /* border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px; */
  margin-left: 0;
}

.tab-button:last-child {
  /* border-top-right-radius: 10px; 
  border-bottom-right-radius: 10px; */
  margin-right: 0;
}

.tab-button:hover {
  background-color: #f7f7f7;
}

.tab-button.active-tab {
  background-color: #37cf37;
  color: white;
  border-color: #37cf37;
}

.price-details {
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
}

.price-container {
  text-align: center;
  margin-top: 20px;
}

.original-price {
  font-size: 16px;
  color: grey;
  text-decoration: line-through;
}

.discounted-price {
  font-size: 36px;
  color: #1d2d50;
  font-weight: bold;
}

.discount-percentage {
  font-size: 20px;
  color: grey;
  font-weight: normal;
}

.final-price-info {
  font-size: 18px;
  color: grey;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .price-container {
    font-size: 14px;
  }

  .original-price {
    font-size: 14px;
  }

  .discounted-price {
    font-size: 28px;
  }

  .discount-percentage {
    font-size: 16px;
  }

  .final-price-info {
    font-size: 16px;
  }

  .tab-button {
    background-color: white;
    color: rgb(155, 155, 155);
    border: 1px solid #37cf37;
    width: 110px;
    height: 90px;
    margin: 0 -2px 0 -2px !important;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: background-color 0.3s, color 0.3s, border-color 0.3s; */
    border-radius: 0; /* Remove border radius for a flat edge */
    box-shadow: none;
  }
}
</style>
